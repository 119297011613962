import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/435782481"
    bibleGroupSrc="https://www.docdroid.net/vK1zlRf/bible-group-homework-7-5-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Christ Centered - Colossians" />
  </Layout>
)

export default SermonPost
